<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Müşteri Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Müşteri Güncelleme",
      items: [
      {
          text: "RTS",
          href: "/",
        },
        {
          text: "Müşteri Listesi",
          href: "/customer",
        },
        {
          text: "Müşteri Güncelleme",
          active: true,
        },
      ],
      newCustomer: {
        customer_name: "",
        customer_phone: "",
        customer_email: "",
        customer_passport_number: "",
        customer_nationality: "",
        isActive: "",
      },
      isActiveOptions: [
        { value: 1, text: "Aktif" },
        { value: 0, text: "Aktif Değil" },
      ],
    };
  },
  mounted() {
    const customerId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/customer/${customerId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.newCustomer = response.data?.data; // Get ile gelen verileri form alanlarına doldur
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    updateCustomer() {
      const customerId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/customer/${customerId}`;

      axios
        .put(api_url, this.newCustomer, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/customer");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="updateCustomer">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_name">Müşteri Adı:</label>
                    <input
                      v-model="newCustomer.customer_name"
                      type="text"
                      class="form-control"
                      id="customer_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_phone">Müşteri Numarası:</label>
                    <input
                      v-model="newCustomer.customer_phone"
                      type="text"
                      class="form-control"
                      id="customer_phone"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_email">Müşteri Mail:</label>
                    <input
                      v-model="newCustomer.customer_email"
                      type="text"
                      class="form-control"
                      id="customer_email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_passport_number">Müşteri Pasaport Numarası:</label>
                    <input
                      v-model="newCustomer.customer_passport_number"
                      type="text"
                      class="form-control"
                      id="customer_passport_number"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customer_nationality">Müşteri Uyruğu:</label>
                    <input
                      v-model="newCustomer.customer_nationality"
                      type="text"
                      class="form-control"
                      id="customer_nationality"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="isActive">Aktif Durumu:</label>
                    <b-form-select
                      v-model="newCustomer.isActive"
                      :options="isActiveOptions"
                      class="form-control"
                      id="isActive"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
